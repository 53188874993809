import tg from "utils/tg";
interface Responce {
  success: number;
  balance: number;
}

export default async (task_id:string | number):Promise< null | Responce> => {
  try {
    const tg_id = tg.initDataUnsafe.user?.id;
    const API_URL = new URL(process.env.REACT_APP_API_URL as string)
    API_URL.pathname += "task_done.php";
    API_URL.searchParams.set("tg_id", String(tg_id));
    API_URL.searchParams.set("task_id", String(task_id));

    const responce = await fetch(API_URL)
    return await responce.json()
  } catch (error) {
    return null
  }
};
