import RatingInfo from "types/rating-info";
import Influencer from "types/rating-info";
import TasksInfo from "types/tasks-info";
import tg from "utils/tg";

export default async ():Promise< null | RatingInfo> => {
  try {
    const tg_id = tg.initDataUnsafe.user?.id;
    const API_URL = new URL(process.env.REACT_APP_API_URL as string)
    API_URL.pathname += "rating_influencers.php";
    API_URL.searchParams.set("tg_id", String(tg_id));

    const responce = await fetch(API_URL)
    return await responce.json()
  } catch (error) {
    return null
  }
};
