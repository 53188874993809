import React, { useContext, useEffect, useState } from "react";
import "./squad.css";
import coin from "images/coin.png";
import ruby from "images/ruby.png";
import info from "images/icons/info.svg";
import timer from 'images/icons/timer.svg'
import userIcon from "images/icons/user-filled.svg";
import PageWrapper from "UI/PageWrapper";
import frormatNumber from "utils/frormat-number";
import Button from "UI/Button";
import { ModalContext } from "hooks/use-modal";
import AppContext from "utils/app-context";
import referalClaim from "api/referal-claim";
import tg from "utils/tg";
import getInfluencers from "api/get-influencers";
import Influencer from "types/rating-info";
import RatingInfo from "types/rating-info";
import { formatTime } from "utils/format-time";

const Squad = () => {
  const tg_id = tg.initDataUnsafe.user?.id;
  const inviteLink = process.env.REACT_APP_SHARE_LINK?.replace('TG_ID',String(tg_id)) || process.env.REACT_APP_SHARE_LINK || ''
  const shareText = process.env.REACT_APP_SHARE_TEXT || '';
  const {setModal} = useContext(ModalContext)
  const {user,updateUserInfo} = useContext(AppContext)
  const [currentPage, setcurrentPage] = useState<'friends' | 'inf'>('friends')
  const [influencers, setinfluencers] = useState<null | RatingInfo>(null)
  const [influencersTimer, setinfluencersTimer] = useState(NaN)
  const handleReferalClaim = async () => {
    const result = await referalClaim()
    updateUserInfo()
  } 

  const copyShareLink = (e:React.MouseEvent) => {
    e.currentTarget.textContent = 'The link is copied ✅'
    navigator.clipboard.writeText(inviteLink)
  }
  const TelegramShare = () => {
      const shareLink = `https://t.me/share/url?url=${inviteLink}&text=${(shareText.replace(/\\n/g, '%0A'))}` 
      tg.openTelegramLink(shareLink)
  }
  useEffect(() => {
    getInfluencers().then(res => {
    if (res) setinfluencers(res)
    })
  }, [])

  const friendsCount = user?.friends || 0

  const getProgressWidth = (currentCount: number, maxCount: number, prevCount: number = 0) => {
    if (currentCount <= prevCount) return 0;
    if (currentCount >= maxCount) return 100;
    return ((currentCount - prevCount) / (maxCount - prevCount)) * 100;
  };
  useEffect(() => {
    let current = influencers?.seconds || 0
    setinfluencersTimer(current)
    const interval = setInterval(() => {
      current--
      setinfluencersTimer(current)
    },1000)
    return () => clearInterval(interval)
  }, [influencers])
  return (
    <PageWrapper className="squad-page">
      <ul className="store-categories">
        <li className="store-categories-item">
          <Button text="Friends" active={currentPage === 'friends'} onClick={() => setcurrentPage('friends')}/>
        </li>
        <li className="store-categories-item">
          <Button text="Influencers" active={currentPage === 'inf'} onClick={() => setcurrentPage('inf')}/>
        </li>
      </ul>
     {currentPage === 'friends' && <div className="friends-list-container">
      <div className="squad-page-header">
        <div className="user-reward">
          <img src={coin} alt="coin-image "width={24} height={24} />
          <span>{(user?.referral_reward || 0) > 0 ? '+' : ''} {frormatNumber(user?.referral_reward || 0)}</span>
          <Button active text="Claim" style={{ padding: "auto 27px" }} onClick={handleReferalClaim}/>
        </div>

        <img src={info} width={21} height={21} 
          onClick={() => setModal({text:'0-10 invited friends – 50 tokens per friend.\n11-100 invited friends – 100 tokens per friend.\n101-500 invited friends – 250 tokens per friend.',title:'Information'})}
        />
      </div>
      <ul className="squad-page-user-stats">
        <li className="user-stat glass-bg">
          <div
            className="user-stat-progress-bar green-bg"
            style={{ width: `${getProgressWidth(friendsCount, 10)}%` }}
          ></div>
          <img src={coin} alt="coin-image "width={24} height={24} />
          <span>50</span>
          <span>/</span>
          <img src={userIcon} alt="coin-image" />
        </li>

        <li className="user-stat glass-bg">
          <div className="user-stat-progress-bar green-bg"
            style={{ width: `${getProgressWidth(friendsCount,100,10)}%` }}
          ></div>
          <img src={coin} alt="coin-image "width={24} height={24} />
          <span>100</span>
          <span>/</span>
          <img src={userIcon} alt="coin-image" />
        </li>

        <li className="user-stat glass-bg">
          <div className="user-stat-progress-bar green-bg"
            style={{ width: `${getProgressWidth(friendsCount, 500,100)}%` }}
          ></div>
          <img src={coin} alt="coin-image "width={24} height={24} />
          <span>250</span>
          <span>/</span>
          <img src={userIcon} alt="coin-image" />
        </li>
      </ul>
        <p className="friends-counter">Friends ({user?.friends}):</p>
        <ul className="friends-list">
          {user?.friends_list && user.friends_list.map(({coins,name,photo}) => (
              <li className="friends-list-item glass-bg">
              <div className="friends-list-item-info">
                <img src={photo} className="friends-list-item-image" alt="" />
                <p>{name}</p>
              </div>
              <div className="friends-list-item-reward">
                <img src={coin} alt="coin-image "width={24} height={24} />
                <span>+ {frormatNumber(coins)}</span>
              </div>
              </li>
          ))}
        </ul>
      </div>}
      {
        currentPage === 'inf' && (
          <>
          <div className="infuencers-timer game-stats-item linear-green">
        <img src={timer} alt="" height={24} width={24}/>
          <p>{formatTime(influencersTimer)}</p>
          </div>
          <ul className="friends-list rating-list influencers-list">
        {influencers && influencers.top_list.map(({name,number,photo,referrals_count,diamonds},i) => {
          const isMy = number === influencers?.my?.number
          return (
          <li className={`friends-list-item glass-bg rating-list-item ${isMy ? 'green-bg my-rating' : ''}`}>
          <div className="friends-list-item-info">
            <span className="rating-position">{number}</span>
            <img
              className="friends-list-item-image"
              src={photo}
            />
            <div className="rating-stats-block">
              <p>{name}</p>
              <div className="rating-stats">
                <img src={userIcon} height={24} width={24} alt="" />
                <span>{frormatNumber(referrals_count || 0)}</span>
              </div>
            </div>
          </div>
          <div className="friends-list-item-reward">
            <img src={ruby} alt="coin-image " width={24} height={24} />
            <span style={{width:'max-content'}}>+ {frormatNumber(diamonds || 0)}</span>
          </div>
        </li>
        )})}
      </ul>
      </>
        )
      }
      {currentPage === 'friends' && <div className="referal-buttons">
        <Button text="Copy Link" onClick={copyShareLink}/>
        <Button active text="Invite Friend" onClick={TelegramShare}/>
      </div>}
    </PageWrapper>
  );
};

export default Squad;
