import { ForwardRefComponent, HTMLMotionProps, motion } from "framer-motion";
import React, { DetailedHTMLProps, HTMLAttributes, memo } from "react";
import { useNavigationType } from "react-router-dom";

interface Props extends HTMLMotionProps<"div"> {
  loading?: boolean;
  onAnimationEnd?: () => void;
}

const transition = {
  duration: 0.1,
};

const PageWrapper = memo((props: Props) => {
  const { loading, children, onAnimationEnd } = props;
  const navigationType = useNavigationType();

  const isGoingBack = navigationType === "POP";

  const animationProps = {
    initial: {
      opacity: 0,
      x: isGoingBack ? -50 : 50,
    },
    animate: { opacity: 1, x: 0,transition},
    exit: {
      opacity: 0,
      x: isGoingBack ? 50 : -50,
      transition,
    },
  };

  return (
    <motion.div
      onAnimationComplete={onAnimationEnd}
      id={window.location.pathname}
      {...animationProps}
      {...props}
      className={`${props.className} page-wrapper`}
    >
      {children}
    </motion.div>
  );
});

export default PageWrapper;
