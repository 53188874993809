import './game.css'
import PageWrapper from 'UI/PageWrapper'
import coin from 'images/coin.png'
import ruby from 'images/ruby.png'
import ticket from 'images/ticket.svg'
import wallet from 'images/wallet.png'
import frormatNumber from 'utils/frormat-number'
import gamePoster from 'images/games/meme-blast-arena.png'
import Button from 'UI/Button'
import { useContext } from 'react'
import AppContext from 'utils/app-context'
import { useNavigate } from 'react-router-dom'
type Props = {}

const Game = (props: Props) => {
  const appContext = useContext(AppContext)

  console.log(appContext.token)

  const navigate = useNavigate()
  return (
    <PageWrapper className='game-page'>
      <div className="game-page-header">
        <div className="user-info">
        <img src={appContext.user?.avatar} width={40} height={40} alt="" style={{borderRadius:'50%'}}/>
          <p>{appContext.user?.name}</p>
          <div className="wallet-btn glass-bg">
            <img src={wallet} alt="" width={24} height={24}/>
          </div>
        </div>
        <div className="user-balance">
        <img src={coin} alt="" width={24} height={24}/>
        <span>{frormatNumber(appContext.user?.balance || 0)}</span>
        </div>
      </div>
      <ul className="user-balance-list">
        <li className="user-balance-list-item glass-bg">
          <img src={ruby} alt="" width={24} height={24} />
          <span>{frormatNumber(appContext.user?.diamonds || 0)}</span>
        </li>
       <li className="user-balance-list-item glass-bg">
          <img src={ticket} alt="" height={24}/>
          <span>{frormatNumber(appContext.user?.tickets || 0)}</span>
        </li>
      </ul>
      <ul className="game-stats">
        <li className="game-stats-item linear-green">
          <p>Personal</p>
          <p>{appContext.user?.rating_personal}</p>
        </li>
        <li className="game-stats-item linear-blue">
          <p>Global</p>
          <p>{appContext.user?.rating_global}</p>
        </li>
      </ul>
      <ul className="game-list">
        <li className="game-card">
          <img className='game-card-poster' src={gamePoster} alt="" />
          <h2>MEME BLAST ARENA</h2>
          <div className="game-card-buttons">
            <Button active text='Play' className='yellow-btn' onClick={() =>  window.location.href = `/game/index.html?token=${appContext.token as string}`}/>
            <Button active text='Boost' onClick={() => navigate('/store')}/>
          </div>
        </li>
      </ul>
    </PageWrapper>
  )
}

export default Game