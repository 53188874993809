import React, { useEffect, useRef, useState } from "react";
import "./rating.css";
import PageWrapper from "UI/PageWrapper";
import Button from "UI/Button";
import timer from 'images/icons/timer.svg'
import getRating from "api/get-rating";
import RatingInfo from "types/rating-info";
import RatingItem from "./RatingItem";
import { formatDuration } from "utils/format-time";
type RatingTimerType = {global:number,personal:number}

const Rating = () => {
  const [ratingPersonal, setRatingPersonal] = useState<RatingInfo | null>(null)
  const [ratingGlobal, setRatingGlobal] = useState<RatingInfo | null>(null)
  const [currentPage, setcurrentPage] = useState<'personal' | 'global'>('personal')
  const [ratingTimer, setratingTimer] = useState<RatingTimerType | null>(null)
  
  const displayItems = currentPage === 'global' ? ratingGlobal?.top_list : ratingPersonal?.top_list
  const currentMy = currentPage === 'global' ? ratingGlobal?.my : ratingPersonal?.my
  
  const linearColor = currentPage === 'personal' ? 'green' : 'blue'
  

  useEffect(() => {
    getRating('global').then((res) => {
      if (res) setRatingGlobal(res)
    })
    getRating('personal').then((res) => {
      if (res) setRatingPersonal(res)
    })
  }, [])

  useEffect(() => {
    
    if (!ratingGlobal || !ratingPersonal || ratingTimer) return
    let time = {global:(ratingGlobal.seconds || 0),personal:(ratingPersonal.seconds || 0)}
    setratingTimer(time)
    const interval = setInterval(() => {
      time.global--
      time.personal--
      setratingTimer(time)
    },1000)
    return () => clearInterval(interval)
  }, [ratingGlobal,ratingPersonal])

  return (
    <PageWrapper className="rating">
      <h1>Rating</h1>
      <ul className="store-categories">
        <li className="store-categories-item">
          <Button active={currentPage === 'personal'} text="Personal" onClick={() => setcurrentPage('personal')}/>
        </li>
        <li className="store-categories-item">
          <Button active={currentPage === 'global'} text="Global" onClick={() => setcurrentPage('global')}/>
        </li>
      </ul>
      <div className={`infuencers-timer game-stats-item linear-${linearColor}`}>
        <img src={timer} alt="" height={24} width={24}/>
          {ratingTimer && <p>{formatDuration(currentPage === 'personal' ? ratingTimer.personal : ratingTimer.global)}</p>}
          </div>
      <ul className="friends-list rating-list">
        {displayItems && displayItems.map((item => {
          const isMy = item.number === currentMy?.number
          return <RatingItem item={item} my={isMy}/>
        }))}
        {(currentMy && currentMy?.number > 100) && <RatingItem item={currentMy} my/>}
      </ul>
    </PageWrapper>
  );
};

export default Rating;
