import TasksInfo from "types/tasks-info";
import tg from "utils/tg";

interface Responce {
  success: number;
  balance: number;
}

const claimDailyQuest = async (
  subtask_id: number | string
): Promise<null | Responce> => {
  try {
    const tgId = tg.initDataUnsafe.user?.id;
    if (!tgId) return null;
    const API_URL = new URL(process.env.REACT_APP_API_URL as string);
    API_URL.pathname += "daily_quest_claim.php";
    API_URL.searchParams.set("tg_id", String(tgId));
    API_URL.searchParams.set("subtask_id", String(subtask_id));

    const responce = await fetch(API_URL);
    return await responce.json();
  } catch (error) {
    return null;
  }
};

export default claimDailyQuest;