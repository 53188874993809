import React, { useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import { useNavigate, useRoutes, useLocation } from "react-router-dom";
import routes from "./routes";
import NavigationBar from "UI/NavigationBar";
import Modal from "UI/Modal";
import useModal from "hooks/use-modal";
import getToken from "api/get-token";
import useAppData from "hooks/use-app-data";

function App() {
  const element = useRoutes(routes.map(({ route }) => route));
  const {ModalContext,ModalContextValue} = useModal();
  const {AppContext, AppContextValue} = useAppData()
  const location = useLocation()

  if (!element) return null;
  return (
    <AppContext.Provider value={AppContextValue}>
      <ModalContext.Provider value={ModalContextValue}>
        <AnimatePresence mode="sync">
          {React.cloneElement(element,{key:location.pathname})}
        </AnimatePresence>
        <NavigationBar />
        <Modal />
      </ModalContext.Provider>
    </AppContext.Provider>
  );
}

export default App;
