import { createContext, Dispatch, ReactNode, SetStateAction, useState } from "react"

type ModalProps = {
    title:string,
    text:string,
}

type ModalContextProps = {
    modal: ModalProps | null
    setModal:Dispatch<SetStateAction<ModalProps | null>>
}
export const ModalContext = createContext<ModalContextProps>({} as ModalContextProps)

export default () => {
    const [modal, setModal] = useState<ModalProps | null>(null)
    const ModalContextValue = {
        modal,setModal
    }
    return {ModalContext,ModalContextValue}
}